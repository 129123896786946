.container{
  display: flex;
  align-items: center;
  width: 100%;
}

.border{
  border-bottom: 1px solid #D5D9F2;
  width: 100%;
}
